import { Button, Modal } from 'antd';
import { mapsAPI } from 'api/maps';
import Map from 'components/map/map';
import SelectAddress from 'components/select/select-address';
import { Position } from 'interfaces/position';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { PositionType } from 'types/map';
import { QUERY_KEY } from 'utils/query-key.enum';

interface Props {
  title?: string;
  onSubmit: (value: Position) => void;
  onCancel: () => void;
  value?: Position;
  disabled?: boolean;
  isMulti?: boolean;
  color?: string;
  onSubmitMulti?: (values: Array<Position>) => void;
  values?: Array<Position>;
  showSubmit?: boolean;
}

const SelectPositionModal = ({
  title,
  onSubmit,
  onCancel,
  value,
  disabled,
  isMulti,
  color,
  onSubmitMulti,
  values,
  showSubmit,
}: Props) => {
  const [position, setPosition] = useState<PositionType | undefined>(
    value ? { lat: value.lat, long: value.lng } : undefined
  );
  const [positions, setPositions] = useState<PositionType[]>(
    values
      ? values.map((value) => ({
          lat: value.lat,
          long: value.lng,
          address: value.address,
          station: value.station,
        }))
      : []
  );
  const [address, setAddress] = useState<string>(value?.address ?? '');

  const { isLoading } = useQuery(
    [QUERY_KEY.FIND_MAP_ADDRESS, position],
    () =>
      position
        ? mapsAPI.findAddress({ lat: position!.lat, lng: position!.long })
        : null,
    {
      enabled: !!position,
      onSuccess: (value) => {
        if (value) {
          setAddress(value.display_name);
        }
      },
    }
  );

  const onCancelHandler = () => {
    onCancel();
  };

  const onSubmitHandler = () => {
    if (isMulti) {
      return onSubmitMulti?.(
        positions.map((position) => ({
          lat: position.lat,
          lng: position.long,
          address: position.address,
          station: position.station,
        }))
      );
    } else {
      if (!position) return onCancel();
      return onSubmit({
        lat: position.lat,
        lng: position.long,
        address: address,
        station: position.station,
      });
    }
  };

  const onSelectAddressHandler = (value: Position) => {
    setPosition({
      lat: value.lat,
      long: value.lng,
      address: value.address,
      station: value.station,
    });
    setAddress(value.address ?? '');
  };

  const onClickMapHandler = (value: PositionType) => {
    setPosition(value);
  };

  const onClickMultiMapHandler = (values: PositionType[]) => {
    setPositions(values);
  };

  const footerBtns = [
    <Button key="back" onClick={onCancelHandler}>
      Huỷ
    </Button>,
  ];

  if (showSubmit || !disabled) {
    footerBtns.push(
      <Button
        key="submit"
        type="primary"
        color="success"
        onClick={onSubmitHandler}
      >
        Xác nhận
      </Button>
    );
  }

  return (
    <Modal
      open
      title={title ?? 'Chọn vị trí'}
      onCancel={onCancelHandler}
      destroyOnClose
      footer={footerBtns}
      centered
      width={'70%'}
    >
      <SelectAddress
        disabled={disabled}
        loading={isLoading}
        showSearch
        value={address}
        style={{ width: '100%', paddingBottom: 8 }}
        onSelectAddress={onSelectAddressHandler}
      />
      <Map
        config={{ zoom: 18 }}
        center={position}
        height={'70vh'}
        onClick={onClickMapHandler}
        isMulti={isMulti}
        color={color}
        onClickMulti={onClickMultiMapHandler}
        positions={positions}
        showSubmit={showSubmit}
      />
    </Modal>
  );
};

export default SelectPositionModal;
