import { locationIcon } from 'assets/svg/location-icon';
import theme from 'config/theme';
import {
  DivIcon,
  LatLngLiteral as LatLngLiteralLeaflet,
  Map as MapType,
} from 'leaflet';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  WMSTileLayer,
} from 'react-leaflet';
import { MapConfig, MarkerType, PolylineType, PositionType } from 'types/map';
import MapEventListener from './event-listener';

interface LatLngLiteral extends LatLngLiteralLeaflet {
  address?: string;
  station?: string;
}

interface Props {
  markers?: MarkerType[];
  config?: MapConfig;
  height: number | string;
  polylines?: PolylineType[];
  onClick?: (value: PositionType) => void;
  center?: PositionType;
  isMulti?: boolean;
  onClickMulti?: (value: Array<PositionType>) => void;
  color?: string;
  positions?: PositionType[];
  showSubmit?: boolean;
  setSelectedId?: (id: string) => void;
  isWMS?: boolean;
}

const DEFAULT_CONFIG: MapConfig = {
  center: {
    lat: 10.796499126609119,
    long: 106.65799955598793,
  },
  zoom: 13,
  minZoom: 12,
  // maxBounds: [
  //   [10.611, 106.458],
  //   [11.021, 106.949],
  // ],
};

const mapPositionToLatLng = (data: PositionType): LatLngLiteral => {
  return { lat: data.lat, lng: data.long };
};

const mapLatLngToPosition = (data: LatLngLiteral): PositionType => {
  return {
    lat: data.lat,
    long: data.lng,
    address: data.address,
    station: data.station,
  };
};

const formatPositionListData = (data: PositionType[]): [number, number][] => {
  return data.map((item) => [item.lat, item.long]);
};

const Map = ({
  markers,
  config,
  height,
  polylines,
  onClick,
  center,
  isMulti,
  color,
  onClickMulti,
  positions,
  showSubmit,
  setSelectedId,
  isWMS = false,
}: Props) => {
  const mapRef = useRef<MapType>(null);
  const [mapReady, setMapReady] = useState(false);
  const [mapConfig, setMapConfig] = useState<MapConfig>({
    ...DEFAULT_CONFIG,
    ...config,
  });

  const onClickMapHandler = (latLng: LatLngLiteral) => {
    if (onClick) {
      const position = mapLatLngToPosition(latLng);
      onClick(position);
    }
  };

  const onClickMultiMapHandler = (latLngs: LatLngLiteral[]) => {
    if (onClickMulti) {
      const positions = latLngs.map((latLng) => mapLatLngToPosition(latLng));
      onClickMulti(positions);
    }
  };

  useLayoutEffect(() => {
    setMapReady(true);
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!center) return;
    mapRef.current.flyTo(mapPositionToLatLng(center), mapRef.current.getZoom());
  }, [center]);

  return mapReady ? (
    <MapContainer
      ref={mapRef}
      style={{ height: height }}
      center={mapPositionToLatLng(
        center ?? positions?.[0] ?? DEFAULT_CONFIG.center!
      )}
      zoom={mapConfig.zoom}
      maxBounds={mapConfig.maxBounds}
      maxBoundsViscosity={1}
      minZoom={mapConfig.minZoom}
      zoomControl={false}
    >
      <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}{r}.png" />

      {isWMS ? (
        <WMSTileLayer
          url="https://maphub.dientoan.vn/geoserver/ows?"
          params={{
            service: 'WMS',
            version: '1.3.0',
            request: 'GetMap',
            format: 'image/png',
            transparent: true,
            layers: 'geonode:so_km_10m',
            styles: 'geonode:so_km_10m',
          }}
        />
      ) : null}

      {markers?.map((marker, index) => (
        <Marker
          key={`marker-${marker.id}-${index}`}
          position={mapPositionToLatLng(marker.position)}
          icon={
            new DivIcon({
              className: 'location-icon',
              html: locationIcon(marker.color),
              iconSize: [40, 40],
            })
          }
          eventHandlers={marker.eventHandlers}
        >
          {marker.renderPopup && <Popup>{marker.renderPopup()}</Popup>}
        </Marker>
      ))}
      {polylines?.map((polyline, index) => (
        <Polyline
          key={`polyline-${polyline.id}-${index}`}
          positions={formatPositionListData(polyline.positionList)}
          color={polyline.color ?? color ?? theme.token?.colorPrimary}
        />
      ))}
      {onClick && (
        <MapEventListener
          initPosition={center ? mapPositionToLatLng(center) : undefined}
          onClick={onClickMapHandler}
          isMulti={isMulti}
          color={color}
          onClickMulti={onClickMultiMapHandler}
          initPositions={
            positions
              ? positions.map((position) => ({
                  lat: position.lat,
                  lng: position.long,
                  address: position.address,
                  station: position.station,
                }))
              : undefined
          }
          showSubmit={showSubmit}
        />
      )}
    </MapContainer>
  ) : (
    <></>
  );
};

export default memo(Map);
